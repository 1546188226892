/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@use '@angular/material' as mat;

@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@import 'variable';

@import 'icons/themify-icons/themify-icons.css';
@import 'icons/font-awesome/css/font-awesome.css';
/*
@import 'icons/flag-icon-css/flag-icon.min.css';
*/
@import 'spinner';
@import 'app';
@import 'header';
@import 'widgets';

@import 'horizontal.scss';

@import 'pages';
@import 'sidebar';
@import 'dark-sidebar';
@import 'responsive';
@import 'rtl';

// Red Theme

.danger {
  @import 'colors/red';
  @include mat.all-legacy-component-themes($red-app-theme);
}

// Blue Theme

.blue {
  @import 'colors/blue';
  @include mat.all-legacy-component-themes($blue-app-theme);
}

// Green Theme

.green {
  @import 'colors/green';
  @include mat.all-legacy-component-themes($green-app-theme);
}

// Dark Theme

.dark {
  .mat-drawer-container {
    color: #8999a0 !important;
  }

  @import 'colors/dark';
  @include mat.all-legacy-component-themes($dark-theme);
}

$custom-typography: mat.define-legacy-typography-config(
        $font-family: 'Poppins',
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`

@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();
