/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*==============================================================
 For Laptop & above all (1650px)
 ============================================================== */

@media (min-width: 1650px) {
  .main-container.boxed {
    max-width: 1920px;
    margin: 0 auto;
  }
}

/*==============================================================
 For Laptop & above all (1370px)
 ============================================================== */

@media (max-width: 1600px) {
  .main-container.boxed {
    max-width: 1300px;
    margin: 0 auto;
  }
}

/*-- ==============================================================
 Ipad & above all(768px)
 ============================================================== */

@media (min-width: 768px) {
  .navbar-header {
    width: 225px;
    flex-shrink: 0;

    .navbar-brand {
      padding-top: 0px;

      .mobile-logo {
        display: none;
      }

      .desktop-logo {
        display: block;
      }
    }
  }

}

@media (max-width: 600px) {
  .responsive-table {
    .header-label {
      width: 80px;
      display: inline-block;
      font-weight: bold;
    }

    .mat-header-row {
      display: none;
    }

    .mat-row {
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 15px;

      &:after {
        min-height: unset;
      }
    }
  }
}

/*-- ==============================================================
 Phone and below ipad(767px)
 ============================================================== */

@media (max-width: 767px) {
  .mat-toolbar-single-row {
    height: 64px !important;
  }

  .no-block {
    display: flex;
  }

  .email-right-panel {
    .col-md-2 {
      width: 100%;
    }
  }

  .icon-box {
    width: 50%;
  }

  .float-sm-left {
    float: left;
  }
}

@media print {
  html .page-wrapper {
    height: 100%;
  }
}
