/* You can add global styles to this file, and also import other style files */

:root {
  --primary-color: #3244b0;
  --secondary-color: #ed8c00;
}

.black-text {
  color: black;
}

.red-text {
  color: red;
}

.blue-text {
  color: var(--primary-color);
}

.orange-text {
  color: var(--secondary-color);
}

.mat-flat-button.mat-yellow,
.mat-raised-button.mat-yellow,
.mat-fab.mat-yellow,
.mat-mini-fab.mat-yellow {
  background-color: #ffb22b;
}

.mat-button.mat-yellow,
.mat-icon-button.mat-yellow,
.mat-stroked-button.mat-yellow {
  color: #ffb22b;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.mat-card .mat-divider-horizontal {
  position: inherit !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.space-between {
  justify-content: space-between;
}

.polipush-header-small-icon {

  display: none;

}

.ct-card-dialog, .preview-dialog-container {

  .mat-dialog-container {


    padding: 0px;

  }

}

.preview-dialog-container {

  .mat-dialog-container {

    .mat-card {

      margin: 0px !important;

    }

  }

}

/*
**
** COMPANIES CUSTOM STYLING
**
*/

.main-container {

  .desktop-logo {

    height: 65px !important;

  }

  .topbar {

    .mat-icon-button, .polipush-header-small-icon {

      margin-top: 10px !important;

    }

  }

  @media screen and (max-width: 500px) {

    .desktop-logo {

      display: block !important;
      height: 55px !important;

    }

  }

  &.customer {

    .polipush-header-small-icon {

      display: block;

    }

    .mat-toolbar {

      background-color: white !important;

    }

    .sidebar-toggle {

      color: #27656b;

    }

  }

}

.generic-snackbar {

  background: #009688ff;
  color: white;

  .mat-simple-snackbar-action {

    color: #ffb22bff;

  }

}


.ngx-datatable .datatable-body .datatable-body-row.datatable-row-odd {

  background-color: rgba(238, 238, 238, 0.47) !important;

}

[hidden] {
  display: none !important;
}

.clearfix {

  overflow: auto;

}

.stepper-actions-cntr::after {

  content: "";
  clear: both;
  display: table;

}

/**********************************************************/
/**********************************************************/
// RESPONSIVE DESIGN

@media screen and (max-width: 500px) {


  .blank-container {

    a.link {

      font-size: 12px;

    }

  }

  h3 {

    line-height: 22px !important;
    font-size: 18px !important;

  }

  h6 {

    line-height: 14px !important;;
    font-size: 12px !important;;
    font-weight: 200 !important;;

  }

  .main-container {

    .polipush-header-small-icon {

      display: block !important;
      height: 35px !important;

    }

    .desktop-logo {

      display: none !important;

    }

  }

  .filters-cntr {

    margin: 10px !important;

  }

  .mat-expansion-panel {

    padding: 10px !important;

    .mat-expansion-panel-header {

      font-size: 12px;
      padding: 0 !important;

      .mat-content {

        flex-direction: column;

      }

    }

    .mat-expansion-panel-body {

      padding: 0px !important;

    }

  }

  .mat-action-row {

    padding: 10px !important;

    .mat-button-base {

      margin-left: 0px !important;
      font-size: 12px !important;
      line-height: 32px !important;
      padding: 0 10px !important;
      width: 100%;

      .mat-icon {

        display: none;

      }

    }

  }

  .mat-card {

    margin: 10px !important;

    .mat-card-avatar {

      display: none !important;

    }

    .mat-card-header {

      padding: 5px 0px !important;

    }

    .mat-card-header-text {

      margin: 0px 8px !important;

    }

    .mat-card-content {

      padding: 10px !important;

    }

  }

  p {

    font-size: 14px;
    line-height: 18px;
    margin-block-start: 10px !important;
    margin-block-end: 10px !important;

  }

  .mat-dialog-container {

    max-width: 99vw;
    max-height: 90vh !important;
    padding: 5px 10px;

    .mat-dialog-actions {

      .mat-button-base {

        margin-left: 0px !important;
        margin-bottom: 5px;
        width: 100%;

      }

    }

  }

  mat-step-header[aria-selected=true] ~ .mat-stepper-vertical-line::before {

    border-left-width: 0;

  }

  .mat-radio-group, .mat-checkbox {

    font-size: 14px;

  }

  .mat-checkbox {

    .mat-checkbox-layout {

      white-space: normal !important;

    }

    .mat-checkbox-inner-container {

      margin-top: 4px;

    }

  }

  .mat-vertical-stepper-content {

    .p-10 {

      padding: 0px !important;

    }

  }

  .mat-list-base {

    .mat-list-text {

      font-size: 12px !important;
      line-height: 15px !important;

      .mat-icon {

        margin-right: 5px !important;

      }

    }

  }

}

// fix pager so that it doesn't wrap and uses available space
.ngx-datatable {

  .datatable-footer-inner {

    display: flex;
    flex-flow: row nowrap; // can't wrap because footer height fixed
    justify-content: space-between; // probably not needed because .page-count flex grows

    > .page-count {

      // remove padding so there's more room in-between
      padding-right: 0 !important;

    }

    > .datatable-pager {

      margin: 0 !important;
      // don't grow, otherwise pager won't be right-aligned
      flex: 0 0 auto !important;

      > .pager {
        // prevent numbers from wrapping
        display: flex !important;
        flex-flow: row nowrap;
      }

    }

  }

}


.mat-form-field-subscript-wrapper {

  font-size: 70% !important;

}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {

  padding: 0.4rem 0.6rem !important;

}

.tox-tinymce.tox-fullscreen {
  z-index: 1050;
  top: 64px !important;
  left: 239px !important;
  right: 222px !important;
  width: calc(100% - 239px) !important;
}

@media screen and (min-width: 1280px) {

  .uploaded-files-cntr.min-height {

    min-height: 195px !important;

  }

}
